<template>
  <nav
    class="tw-flex tw-h-16 tw-w-full tw-items-center tw-justify-between tw-bg-white tw-px-4 tw-text-sm"
  >
    <div class="tw-flex tw-h-full tw-items-center">
      <a
        class="tw-mr-2 tw-flex tw-h-full tw-cursor-pointer tw-flex-col tw-justify-center tw-text-earl-1000 tw-no-underline hover:tw-opacity-50"
        @click="navigateHome"
      >
        <img
          v-if="logoUrl"
          :src="logoUrl"
          class="tw-mr-4 tw-h-12 tw-rounded-lg lg:tw-mr-16"
        />
        <span
          v-else
          class="tw-w-20 tw-truncate tw-font-bold lg:tw-w-36"
        >
          {{ organizationName }}
        </span>
      </a>
      <a
        v-if="currentUser && $vuetify.display.mdAndUp"
        :class="`tw-flex tw-h-full tw-cursor-pointer tw-flex-col tw-justify-center tw-px-2 tw-font-bold tw-text-earl-1000 hover:tw-opacity-50 ${activeClasses}`"
        @click="navigateHome"
      >
        <span>Home</span>
      </a>
    </div>

    <!-- Only user email and form submit button for authenticated portal -->
    <div
      v-if="currentUser"
      class="tw-flex"
    >
      <router-link
        v-if="$vuetify.display.mdAndUp"
        to="/ticket"
      >
        <SpiceworksButton
          button-type="primary"
          icon="mdi-plus"
          text="Submit a ticket"
        />
      </router-link>

      <v-menu location="bottom">
        <template #activator="{ props: activatorProps }">
          <div
            class="tw-ml-5 tw-whitespace-nowrap lg:tw-ml-10"
            v-bind="activatorProps"
          >
            <UserAvatar
              :color="theme"
              :size="36"
              class="mr-2"
              :user="currentUser"
            />
            <span
              v-if="$vuetify.display.mdAndUp"
              class="email"
              >{{ currentUser.email }}</span
            >
            <v-icon>mdi-menu-down</v-icon>
          </div>
        </template>

        <v-list>
          <v-list-item @click="doLogout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </nav>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { logout } from '@/src/portal/services/registrations-service.js';
import UserAvatar from '@/src/common/components/UserAvatar.vue';
import SpiceworksButton from '@/src/common/components/SpiceworksButton.vue';

const props = defineProps({
  logoUrl: {
    type: String,
    default: '',
  },
  theme: {
    type: String,
    required: true,
  },
  organizationName: {
    type: String,
    required: true,
  },
  currentUser: {
    type: Object,
    default: null,
  },
});

const route = useRoute();
const router = useRouter();

const activeClasses = computed(() => {
  return route.path === '/' ? 'tw-border-earl-1000 tw-border-b-2 tw-pt-1' : '';
});

const navigateHome = async (e) => {
  e.preventDefault();
  if (props.currentUser && route.path !== '/') {
    await router.push({ name: 'Landing' });
  }
};

const doLogout = async () => {
  await logout();
  window.location.reload();
};
</script>

<style scoped>
.email {
  max-width: 12rem;
  @apply tw-inline-block tw-truncate tw-align-middle tw-text-earl-1000;
}
</style>
