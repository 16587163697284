import axios from 'axios';

// Global configuration for requests
axios.defaults.headers.common['X-CSRF-TOKEN'] =
  document.querySelector('[name="csrf-token"]').content;

export async function requestLogin(loginData) {
  const requestUrl = '/portal/registrations';

  try {
    const response = await axios.post(requestUrl, loginData);
    return response.data;
  } catch (error) {
    /* istanbul ignore next */
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function logout() {
  const requestUrl = '/portal/logout';
  await axios.get(requestUrl);
}
