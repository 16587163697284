<template>
  <v-app class="tw-bg-earl-100">
    <NavBar
      :logo-url="portalSettings.logoUrl"
      :theme="portalSettings.theme"
      :organization-name="portalSettings.organizationName"
      :current-user="currentUser"
    />
    <PortalTitle
      :theme="portalSettings.theme"
      :welcome-message="portalSettings.welcomeMessage"
      :class="{ 'tw-hidden sm:tw-block': route.name === 'Ticket' }"
    />
    <v-main class="tw-z-10 tw-text-sm tw-font-medium tw-text-earl-1000">
      <v-container class="tw-p-0">
        <slot></slot>
      </v-container>
    </v-main>
    <FullFooter :class="{ 'tw-hidden lg:tw-block': route.name === 'Ticket' }" />
    <MessageSnackbar />
  </v-app>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import NavBar from '@/src/portal/components/NavBar.vue';
import FullFooter from '@/src/common/components/FullFooter.vue';
import PortalTitle from '@/src/portal/components/PortalTitle.vue';
import MessageSnackbar from '@/src/common/components/MessageSnackbar.vue';

const store = useStore();
const route = useRoute();

const portalSettings = computed(() => store.state.portalSettings);
const currentUser = computed(() => store.state.currentUser);
</script>

<style>
.evidon-consent-button {
  visibility: hidden !important;
}
</style>
