<template>
  <article
    :class="`tw-w-full tw-rounded tw-border tw-bg-white tw-p-4 tw-text-sm tw-shadow-portal sm:tw-p-5`"
  >
    <div class="tw-flex tw-flex-col">
      <span
        ref="textElement"
        class="tw-text-overflow-ellipsis tw-line-clamp-2 tw-overflow-hidden lg:tw-line-clamp-5"
      >
        <AnnouncementIcon
          name="announcement-icon"
          class="tw-inline"
        />
        {{ announcement }}
      </span>
      <div
        v-if="truncated"
        class="tw-cursor-pointer tw-lowercase tw-underline"
        @click="isShowingDialog = true"
      >
        read more
      </div>
    </div>
    <InfoModal
      v-model:is-showing-dialog="isShowingDialog"
      title="Announcements"
      :text="announcement"
      @close-dialog="closeDialog"
    >
      <AnnouncementIcon
        name="announcement-icon"
        class="tw-mr-2 tw-inline"
      />
    </InfoModal>
  </article>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import InfoModal from '@/src/common/components/InfoModal.vue';
import AnnouncementIcon from '@/assets/announcement-icon.svg';

defineProps({
  announcement: {
    type: String,
    required: true,
  },
});

const isShowingDialog = ref(false);
const truncated = ref(false);
const textElement = ref(null);

const checkTruncation = () => {
  nextTick(() => {
    const el = textElement.value;
    truncated.value = el.offsetHeight < el.scrollHeight;
  });
};

onMounted(() => {
  window.addEventListener('resize', checkTruncation);
  checkTruncation();
});

onUnmounted(() => {
  window.removeEventListener('resize', checkTruncation);
});

const closeDialog = () => {
  isShowingDialog.value = false;
};
</script>
